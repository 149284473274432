@import url('https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500,500i,600,600i,700,700i');
/* -------------------------------------------------------------------------------
    Variables
   ------------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------------
    HTML Elements
   ------------------------------------------------------------------------------- */
body {
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	position: relative;
	padding-top: 137px;
	font-family: 'Roboto', sans-serif;
	font-weight: 400;
}
body.blog, body.post-template-default {
	padding-top: 0px;
}
body.blog .cars-wrapper, body.post-template-default .cars-wrapper {
	display: none;
}
h1, .h1, h2, .h2, h3, .h3, blockquote, th {
	font-family: 'Roboto', sans-serif;
	font-weight: 600;
}
h1 small, .h1 small, h2 small, .h2 small, h3 small, .h3 small {
	color: #0a0a0a;
}
h1, .h1 {
	font-size: 3rem;
	margin-bottom: 0.5em;
	color: #004c97;
	/* border-bottom: 4px solid $secondary; */
	padding-bottom: 0.3em;
}
h1 small, .h1 small {
	color: #004c97;
	font-size: 100%;
}
h2, .h2 {
	margin-top: 1em;
	margin-bottom: 1em;
	font-size: 1.6rem;
	border-bottom: 2px solid #f8cb45;
	padding-bottom: 0.25em;
	color: #003668;
}
h3, .h3 {
	font-size: 1.4rem;
	margin-top: 1em;
	margin-bottom: 1em;
	border-bottom: 1px solid #f8cb45;
	padding-bottom: 0.2em;
	color: #003668;
}
p {
	margin-bottom: 1em;
}
p small {
	font-size: 90%;
	font-weight: normal;
}
a, a i {
	transition: all 0.3s ease;
}
.page-content li {
	margin-bottom: 5px;
}
small {
	font-weight: 300;
}
:focus, :visited, :hover, :active, a:focus, a:visited, a:hover, a:active {
	outline: 0;
	text-decoration: none;
}
u:hover {
	text-decoration: underline;
}
/* -------------------------------------------------------------------------------
    Custom Helpers
   ------------------------------------------------------------------------------- */
.container {
	position: relative;
}
.bg {
	background-attachment: fixed;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
}
.shadow {
	box-shadow: 2px 2px 8px 0px rgba(0,0,0,0.15);
}
.overlay {
	display: none;
	position: fixed;
	z-index: 999;
	width: 100%;
	height: 100%;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: rgba(0,0,0,0.8);
}
.image-overlay {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: rgba(0,0,0,0.5);
}
.image-frame {
	background-color: #fff;
	border: 1px solid rgba(0,0,0,0.125);
	border-radius: 0.25rem;
	padding: 5px;
}
.center-absolute-x {
	position: absolute;
	margin: 0;
	padding: 0;
	width: 100%;
	left: 50%;
	transform: translateX(-50%);
}
.center-absolute-y {
	position: absolute;
	margin: 0;
	padding: 0;
	width: 100%;
	top: 50%;
	transform: translateY(-50%);
}
.center-absolute-xy {
	position: absolute;
	margin: 0;
	padding: 0;
	width: 100%;
	top: 50%;
	transform: translateY(-50%);
}
.center-block-x {
	margin-left: auto !important;
	margin-right: auto !important;
}
.center-flex-y {
	/* add class to parent of centred content */
	display: flex;
	flex-direction: column;
	justify-content: center;
}
.center-flex-xy {
	/* add class to parent of centred content */
	display: flex;
	justify-content: center;
	align-items: center;
}
/* -------------------------------------------------------------------------------
    Components
   ------------------------------------------------------------------------------- */
.page {
	min-height: 100vh;
	margin-top: -298px;
	padding-top: 298px;
}
.component {
	position: relative;
}
.component-calls-to-action, .component-carousel, .component-content, .component-form-all, .component-form-contact, .component-centered-text, .component-text-image, .component-image-text, .component-slider, .component-portfolio, .component-tabs, .component-gallery, .component-galleries, .spaced {
	padding-top: 1.5em;
	padding-bottom: 1.5em;
}
.component-header header {
	min-height: 100vh;
}
.component-carousel {
	padding-top: 0;
}
/* Carousel & Slider */
.slick-slide {
	padding: 0 1em;
	height: 20vw;
}
.slick-list {
	margin: 0 -1em;
}
.carousel-item .carousel-caption {
	left: 0;
	right: 0;
	bottom: 0;
	padding: 10px 15% 20px;
	background: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 100%);
}
.carousel-item .carousel-caption h5, .carousel-item .carousel-caption p {
	display: inline-block;
	background: #000;
	padding: 5px 10px;
}
.carousel-item .carousel-caption h5 {
	font-weight: 700;
	margin-bottom: 0.25rem;
}
.carousel-control-next, .carousel-control-prev {
	opacity: 0.4;
	transition: 0.3s ease opacity;
	z-index: 2;
	top: 60%;
}
.carousel-control-next.slick-disabled, .carousel-control-prev.slick-disabled, .carousel-control-next.disabled, .carousel-control-prev.disabled {
	opacity: 0.2 !important;
	cursor: default;
}
.carousel-control-next:focus, .carousel-control-prev:focus {
	opacity: 0.6;
}
.carousel-control-next:hover, .carousel-control-prev:hover {
	opacity: 1;
}
.carousel-control-next .carousel-control-next-icon, .carousel-control-prev .carousel-control-next-icon, .carousel-control-next .carousel-control-prev-icon, .carousel-control-prev .carousel-control-prev-icon {
	width: 20px;
	height: 20px;
}
.carousel-indicators {
	bottom: 0;
}
.carousel-indicators li {
	width: 5px;
	height: 5px;
	border-radius: 50%;
	opacity: 1;
	transition: 0.3s ease all;
	cursor: pointer;
}
.carousel-indicators li.active, .carousel-indicators li.slick-active {
	background: #fff;
}
.carousel-indicators li:hover {
	background: rgba(255,255,255,0.8);
}
.slider-mode-text .slick-slide {
	padding: 1em 4em;
	text-align: center;
}
.slider-mode-text .carousel-indicators {
	bottom: -40px;
}
.slider-mode-text .carousel-control-prev {
	left: -8%;
}
.slider-mode-text .carousel-control-next {
	right: -8%;
}
@media only screen and (max-width: 768px) {
	.slider-mode-text .carousel-control-prev {
		left: 0%;
	}
	.slider-mode-text .carousel-control-next {
		right: 0%;
	}
}
/* Cards / Portfolio */
.card .card-body {
	min-height: 310px;
}
.card .card-body .card-thumbnail {
	max-height: 140px;
	overflow: hidden;
}
.card .card-body .card-text {
	overflow: hidden;
}
.bg-image {
	background-size: auto;
	background-attachment: scroll;
}
.flags {
	border-left: none !important;
	border-right: none !important;
}
.flags img {
	width: 35px;
	opacity: 0.35;
}
.flags .active img, .flags :hover img {
	opacity: 1;
}
.cars-wrapper {
	overflow: hidden;
}
.cars-left {
	z-index: 4;
	left: 1260px;
	bottom: 0;
	width: 5076px;
	animation: slide-left 30s linear infinite;
}
.cars-right {
	z-index: 3;
	right: 941px;
	bottom: 0;
	width: 5076px;
	animation: slide-right 30s linear infinite;
}
#headerCarousel {
	z-index: 5;
}
/* -------------------------------------------------------------------------------
    Style Bootstrap Classes
   ------------------------------------------------------------------------------- */
.card {
	margin-bottom: 30px;
}
.btn, a.btn, input.btn, button.btn {
	font-family: 'Roboto', sans-serif;
	font-weight: 600;
	font-size: 14px;
	text-transform: uppercase;
	font-weight: 500;
}
@media only screen and (min-width: 992px) {
	#nav {
		height: 48px;
	}
}
.subnav {
	top: 48px;
	z-index: 1020;
}
.has-subnav {
	margin-top: 48px;
}
#nav .navbar-nav > li:first-child > a i {
	margin-right: 0;
}
#nav .navbar-nav > li:first-child > a .nav-home {
	display: none;
}
#nav .navbar-nav > li > a {
	padding-top: 13px;
	padding-bottom: 13px;
	font-family: 'Roboto', sans-serif;
	font-weight: 600;
	font-size: 14px;
	text-transform: uppercase;
	font-weight: 500;
}
#nav .navbar-nav > li > a i {
	text-shadow: 1px 1px 0px rgba(255,255,255,0.1);
	color: #003668;
	margin-right: 5px;
}
#nav .navbar-nav > li > a:hover i {
	text-shadow: 1px 1px 0px rgba(0,0,0,0.3);
	color: #f8cb45;
}
#nav .navbar-nav .dropdown-menu {
	font-size: 12px;
	margin: 0;
	padding: 0;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
	border-bottom-left-radius: 0.25rem;
	border-bottom-right-radius: 0.25rem;
	border: 2px solid #fff;
	border-top: 0;
	border-bottom: 0;
	box-shadow: 1px 1px 5px 2px rgba(0,0,0,0.1);
}
#nav .navbar-nav .dropdown-menu li {
	width: 100%;
	margin-left: 0 !important;
	border-top: 1px solid rgba(0,0,0,0.1);
}
#nav .navbar-nav .dropdown-menu li:first-child {
	border-top: none;
}
#nav .navbar-nav .dropdown-menu li:last-child .nav-link {
	border-bottom-left-radius: 0.25rem;
	border-bottom-right-radius: 0.25rem;
}
#nav .navbar-nav .dropdown-menu li .nav-link {
	color: #003668;
	padding-top: 5px;
	padding-bottom: 5px;
	margin-left: -2px;
	margin-right: -2px;
}
#nav .navbar-nav .dropdown-menu li .nav-link:hover {
	color: #0a0a0a;
	background: #f8cb45;
}
#nav .navbar-nav .dropdown-menu li.current-menu-item .nav-link {
	color: #0a0a0a;
	background: #f8cb45;
}
#nav .dropdown:hover > .dropdown-menu {
	display: block;
}
#nav .nav > li {
	border-left: 1px solid rgba(0,0,0,0.15);
}
#nav .nav > li:last-child {
	border-right: 1px solid rgba(0,0,0,0.15);
}
#nav .nav > li > a {
	color: #fff;
	padding-left: 8px;
	padding-right: 8px;
}
#nav .nav > li > a:hover {
	background: #003668;
}
#nav .nav > li.active, #nav .nav > li.current_page_parent {
	border-left: 1px solid rgba(0,0,0,0.2);
}
#nav .nav > li.active > a, #nav .nav > li.current_page_parent > a {
	background: #003668;
	color: #f8cb45;
}
#nav .nav > li.active > a i, #nav .nav > li.current_page_parent > a i {
	color: #f8cb45;
}
.location-nav {
	height: 100%;
}
.location-nav li {
	font-size: 13px;
}
.location-nav li a {
	padding: 0;
	color: #003668;
}
.location-nav li a:hover {
	color: #004c97;
}
.location-nav li a i {
	color: #f8cb45;
}
ul.features-list li {
	margin-bottom: 12px;
	padding-bottom: 12px;
	border-bottom: 1px solid rgba(0,0,0,0.1);
}
ul.features-list li i {
	margin: 3px 8px 10px 0;
	color: #004c97;
	float: left;
}
.flags a {
	padding: 15px 10px 17px 10px !important;
}
.flags .fab {
	text-shadow: none !important;
	color: #fff !important;
	padding-left: 7px;
	padding-right: 2px;
	font-size: 16px;
	transform: translateY(2px);
}
/* -------------------------------------------------------------------------------
    Footer
   ------------------------------------------------------------------------------- */
#footer {
	margin-top: 40px;
	padding: 40px 0px;
	font-size: 90%;
	-webkit-font-smoothing: subpixel-antialiased;
	background: rgba(0,76,151,0.1);
	border-top: 1px solid rgba(0,76,151,0.15);
}
#footer li {
	margin-bottom: 10px;
}
#footer .fab {
	color: #666;
	margin-right: 5px;
}
#footer .footer-logo-img {
	margin-bottom: 30px;
}
#footer .credit {
	font-size: 12px;
}
#footer .contact-list {
	margin-bottom: 0;
	padding-bottom: 0;
	font-weight: 300;
}
#footer .contact-list i {
	width: 20px;
	color: #004c97;
}
#footer .contact-list strong {
	width: 50px;
	display: inline-block;
}
/* -------------------------------------------------------------------------------
    Forms
   ------------------------------------------------------------------------------- */
.quote-form-wrap {
	z-index: 5 !important;
	border: 3px solid rgba(255,255,255,0.6);
}
.quote-form-wrap label {
	margin-bottom: 0;
	font-size: 14px;
	font-weight: 500;
}
.quote-form-wrap .form-group {
	margin-bottom: 0.6rem;
}
.quote-form-wrap .form-control {
	padding: 0.35rem 0.75rem;
	font-size: 13px;
	border: 1px solid rgba(0,0,0,0.2);
}
.sticky-offset {
	top: 68px;
}
label {
	font-family: 'Roboto', sans-serif;
	font-weight: 400;
}
.custom-control label {
	font-family: 'Roboto', sans-serif;
	font-weight: 400;
}
input, select, textarea {
	font-family: 'Roboto', sans-serif;
	font-weight: 600;
	font-size: 14px;
	font-weight: 400;
}
.result {
	margin-top: 20px;
	margin-bottom: 40px;
	display: none;
}
.result :first-child {
	margin-top: 0;
	padding-top: 0;
}
.parsley-errors-list {
	margin: 0;
	padding: 0;
	list-style: none;
	color: #f00;
	display: block;
	overflow: hidden;
	font-size: 11px;
	margin-top: 5px;
}
.datetimepicker .parsley-errors-list {
	position: absolute !important;
	top: 26px !important;
}
.parsley-error, :focus.parsley-error {
	border-color: #f00;
}
.form-control::-webkit-input-placeholder {
	color: #999;
}
.form-control:-moz-placeholder {
	color: #999;
}
.form-control::-moz-placeholder {
	color: #999;
}
.form-control:-ms-input-placeholder {
	color: #999;
}
.form-control.placeholder {
	color: #999;
}
/* -------------------------------------------------------------------------------
    Media Queries
   ------------------------------------------------------------------------------- */
@media (min-width: 1500px) {
	.cars-left, .cars-right {
		bottom: 20px;
	}
}
@media only screen and (max-width: 1200px) {
	#menu-german-navigation a {
		font-size: 12px !important;
	}
}
@media (min-width: 992px) {
	.container .hidden-menu {
		display: none !important;
	}
	.mobile-links, .container ul.mobile-flags {
		display: none !important;
	}
}
@media only screen and (max-width: 992px) {
	.nav li {
		border-left: 1px solid transparent !important;
		border-right: 1px solid transparent;
	}
	.top-header {
		margin-right: 10px !important;
		margin-left: 10px !important;
		max-width: 95%;
	}
	#navbar {
		margin-top: 0.5em;
	}
	.navbar-toggler {
		background-color: #fff;
	}
	#nav .navbar-nav > li:first-child > a .nav-home {
		display: inline-block;
		margin-left: 5px;
	}
	.lang-flags {
		display: inline-block;
	}
	.lang-flags li {
		float: left;
	}
	.lang-flags li:first-child a {
		margin-left: 8px;
	}
	.lang-flags li:last-child a {
		position: relative;
		top: 0px;
	}
	.container .hidden-menu {
		display: none !important;
	}
	h1 {
		font-size: 2rem;
		margin-top: 20px;
	}
	.desktop-flags {
		display: none;
	}
}
@media only screen and (max-width: 768px) {
	.location-nav {
		display: none;
	}
}
@media only screen and (max-width: 576px) {
	.lang-flags li:last-child a {
		position: relative;
		top: 0px;
	}
}
@-moz-keyframes slide-left {
	0% {
		transform: translate3d(0, 0, 0);
	}
	100% {
		transform: translate3d(-1720px, 0, 0);
	}
}
@-webkit-keyframes slide-left {
	0% {
		transform: translate3d(0, 0, 0);
	}
	100% {
		transform: translate3d(-1720px, 0, 0);
	}
}
@-o-keyframes slide-left {
	0% {
		transform: translate3d(0, 0, 0);
	}
	100% {
		transform: translate3d(-1720px, 0, 0);
	}
}
@keyframes slide-left {
	0% {
		transform: translate3d(0, 0, 0);
	}
	100% {
		transform: translate3d(-1720px, 0, 0);
	}
}
@-moz-keyframes slide-right {
	0% {
		transform: translate3d(0, 0, 0);
	}
	100% {
		transform: translate3d(1720px, 0, 0);
	}
}
@-webkit-keyframes slide-right {
	0% {
		transform: translate3d(0, 0, 0);
	}
	100% {
		transform: translate3d(1720px, 0, 0);
	}
}
@-o-keyframes slide-right {
	0% {
		transform: translate3d(0, 0, 0);
	}
	100% {
		transform: translate3d(1720px, 0, 0);
	}
}
@keyframes slide-right {
	0% {
		transform: translate3d(0, 0, 0);
	}
	100% {
		transform: translate3d(1720px, 0, 0);
	}
}
